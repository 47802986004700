import React, { useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { App } from '@layouts';
import useUser from '@hooks/useUser';
import { SplashScreen, PostsLoader, Post } from '@components';
import useBookmarks from '@hooks/useBookmarks';
import useABTesting from 'hooks/useABTesting';
import { Container } from '@mui/material';

const Bookmarks = () => {
  const { t } = useABTesting({}, 'pages.bookmarks');
  const { session } = useUser();
  const {
    fetch: { mutate: fetchBookmarkedPosts, isLoading },
    bookmarkedPosts,
  } = useBookmarks();

  useEffect(() => {
    if (!session) {
      navigate('/sign-in');
    } else {
      fetchBookmarkedPosts();
    }
  }, []);

  const onRefresh = async (resolve) => {
    await fetchBookmarkedPosts();
    return resolve();
  };

  if (!session) {
    return <SplashScreen />;
  }

  return (
    <App onRefresh={onRefresh}>
      <Container maxWidth="sm">
        <Typography color="primary" variant="h4">
          {t('title')}
        </Typography>
        <Typography variant="body1" component="p" marginBottom="1.25rem">
          {t('description')}
        </Typography>
        {isLoading ? (
          <PostsLoader />
        ) : (
          <>
            {bookmarkedPosts?.length ? (
              <Stack direction="column" spacing={2}>
                {bookmarkedPosts.map((post) => (
                  <Post key={post.id} {...post} />
                ))}
              </Stack>
            ) : (
              <Stack direction="column" spacing={2} sx={{ my: 2 }}>
                <Typography variant="body1">{t('empty')}</Typography>
                <Link to="/">
                  <Button variant="contained" size="medium">
                    {t('return_feed')}
                  </Button>
                </Link>
              </Stack>
            )}
          </>
        )}
      </Container>
    </App>
  );
};

export default Bookmarks;
